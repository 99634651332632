.title {
  color: #152935;
  font-size: 24px;
  font-family: 'Roboto Condensed';
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.content {
  font-family: 'Roboto';
  width: 648px;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #152935;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 233px;
  white-space: pre-wrap;
}

.img {
  margin: 40px auto 0px auto;
  display: block;
}

.socialBox {
  width: 905px;
  height: 312px;
  margin: 40px auto 0px auto;
  display: flex;
  justify-content: space-between;
}

.menuBox {
  width: 851px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-top: 72px;
}

.linkPlayer {
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #152935;
  text-decoration: none;
  width: 308px;
  text-align: center;
}

.linkPlayer:hover {
  color: red;
}

.linkBox {
  display: flex;
  margin: auto;
  justify-content: space-between;

  width: 926px;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.secBottom {
  width: 890px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 41px;
}

.bottomText {
  font-family: 'Roboto';
  width: 890px;
  white-space: pre-wrap;
  text-align: right;
  margin: auto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #152935;
  margin-bottom: 40px;
}
