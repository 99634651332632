// // Core variables and mixins
// @import "bootstrap/variables";
// @import "bootstrap/mixins";
//
// // Reset
// @import "bootstrap/normalize";
// @import "bootstrap/print";
// @import "bootstrap/utilities";

// gloveworks
// @import "./gloveworks.variables";
// @import "./gloveworks.common";
// @import "./gloveworks.custom-glove";
// @import "./gloveworks.order";
// @import "./gloveworks.about";


.clearfix:before {
	content: " ";
	display: table;
}

.clearfix::after {
	content: " ";
	display: table;
	clear: both;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.tip {
	position: relative;
}

.tooltip {
	transform: translateY(2px);
	margin-left: 5px;
	cursor: pointer;
}

.tip-detail-price {
	display: none;
	position: absolute;
	width:312px; padding: 5px 10px 5px 10px;
	background-color: #edd167;
	color: #251f1b;
	letter-spacing: 0;
	text-align: left;
	font-family: 'Metropolis', sans-serif;
	z-index: 2000;
	font-weight: '600';
	font-size: 11px
}

.tip:hover .tip-detail-price { display: block; }

select {
  text-transform: none;
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
}
