@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif);
.menu:hover {
  text-decoration: underline;
}

.clearfix:before{content:" ";display:table}.clearfix::after{content:" ";display:table;clear:both}.pull-right{float:right !important}.pull-left{float:left !important}.tip{position:relative}.tooltip{transform:translateY(2px);margin-left:5px;cursor:pointer}.tip-detail-price{display:none;position:absolute;width:312px;padding:5px 10px 5px 10px;background-color:#edd167;color:#251f1b;letter-spacing:0;text-align:left;font-family:"Metropolis",sans-serif;z-index:2000;font-weight:"600";font-size:11px}.tip:hover .tip-detail-price{display:block}select{text-transform:none;color:inherit;font:inherit;margin:0;outline:none}
.title{color:#152935;font-size:24px;font-family:"Roboto Condensed";font-weight:bold;text-align:center;margin-top:50px;margin-bottom:20px}.content{font-family:"Roboto";width:648px;font-size:18px;font-weight:300;font-style:normal;font-stretch:normal;line-height:1.33;color:#152935;margin:auto;margin-top:60px;margin-bottom:233px;white-space:pre-wrap}.img{margin:40px auto 0px auto;display:block}.socialBox{width:905px;height:312px;margin:40px auto 0px auto;display:flex;justify-content:space-between}.menuBox{width:851px;display:flex;align-items:center;justify-content:space-between;margin:auto;margin-top:72px}.linkPlayer{font-family:"Roboto Condensed";font-size:18px;font-weight:600;font-style:normal;font-stretch:normal;line-height:1.33;letter-spacing:.5px;color:#152935;text-decoration:none;width:308px;text-align:center}.linkPlayer:hover{color:red}.linkBox{display:flex;margin:auto;justify-content:space-between;width:926px;margin-top:30px;margin-bottom:30px;flex-wrap:wrap}.secBottom{width:890px;display:flex;justify-content:space-between;margin:auto;margin-top:41px}.bottomText{font-family:"Roboto";width:890px;white-space:pre-wrap;text-align:right;margin:auto;font-size:18px;font-weight:300;font-style:normal;font-stretch:normal;line-height:1.33;color:#152935;margin-bottom:40px}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,p
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  line-height: 1;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', 'Roboto', 'Metropolis', 'Spoqa Han Sans', 'Noto Serif', sans-serif;
}

select::-ms-expand {
    display: none;
}

div {
	outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

